.labelpesqusia{
    margin-top: 100px;
}

.Menu{
    display: flex;
    list-style: none;
    padding: 0px;
    font-size: 20px;
    box-sizing: border-box;
}
.item {
    box-sizing: border-box;
    width: 170px;
    height: 170px;
    background-color: white;
    margin: 5px;
    color: #282c34;
    padding: 5px;
}
.item h3{
    margin: 15px;
}
.categoria {
    font-size: 12px;
}
.adicionar {
    width: 100%;
    height: 18%;
    background-color: #282c34;
    color: white;
    border: none;
}
.pesquisa {
    width: 300px;
    height: 30px;
    box-sizing: border-box;
}
.botaopesquisa{
    height: 30px;
    width: 100px;
}
