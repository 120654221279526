.Sale{
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.subtotal {
    width: 100%;
    text-align: left;
}
.carrinho {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    font-size: 20px;
    box-sizing: border-box;
    max-width: 100wh;
  }
  
  .carrinho li{
    box-sizing: border-box;
    width: 160px;
    height: 150px;
    background-color: white;
    margin: 5px;
    color: #282c34;
    padding: 5px;
  }